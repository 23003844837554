// @ts-nocheck
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ImageList, ImageListItem, MenuItem, Select, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import * as DocumentPicker from 'expo-document-picker';
import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useGlobalContext } from '../../contexts/globalContext';
import { useNetworkStatus } from '../../hooks/useNetworkStatus';
import { useResource } from '../../hooks/useResource';
import ConsultingSessionService from '../../services/ConsultingSessionService';
import UserSettingService from '../../services/UserSettingService';
import SCREEN from '../../utils/constants/SCREEN';
import filterMemberOptions from '../../utils/filterMemberOptions';
import H from '../../utils/helper';
import { OverlapSessionIndicator } from '../AddHours/OverlapSessionIndicator';
import { OfflineIndicator } from '../Common/OfflineIndicator';


const ConsultingSessionEnterForm = ({ navigation, route }) => {
  const theme = useTheme();
  // const helper = new H();
  const isConnected = useNetworkStatus();
  const genericButtonStyle = {
    width: 350,
    height: 50,
    alignSelf: 'center',
    color: theme.palette.white,
    marginBottom: 15,
  };
  const timeList = ['0:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30'];
  const styles = StyleSheet.create({
    savebtn: {
      ...genericButtonStyle,
      backgroundColor: theme.palette.green,
    },
    cancelbtn: {
      ...genericButtonStyle,
      backgroundColor: '#84868a',
    },
    photoPickBtn: {
      backgroundColor: theme.palette.green,
      color: theme.palette.white,
    }
  });
  const settings = UserSettingService.getSettings();
  const { auth, setMemberName, setHistoryMemberID } = useGlobalContext();
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date().toISOString()));
  const [startDate, setstartDate] = useState(null);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(1);
  const [activityUID, setActivityUID] = useState([]);
  const [memberUIDS, setMemberUIDS] = useState([]);
  const [cultureUID, setCultureUID] = useState(settings?.selectedCulture || 0);
  const [showError, setShowError] = useState(false);
  const [showErrorTime, setShowErrorTime] = useState(false);
  const [showErrorMember, setShowErrorMember] = useState(false);
  const [showOfflineDialog, setShowOfflineDialog] = useState(false);
  const [comment, setComment] = useState('');
  const [selectedImages, setSelectedImages] = useState({});
  const [selectedTime, setSelectedTime] = useState('1:00');
  const [activitiesSettings] = useState(() =>
    UserSettingService.getSettings()
  );


  const handlePhotoPick = async () => {
    const { canceled, assets, output } = await DocumentPicker.getDocumentAsync(
      {
        type: 'image/*',
        multiple: true,
      },
    );

    if (!canceled && assets?.length) {
      const compressedImages = {};
      let count = 0;
      for (const asset of assets) {
        if (count > 4) break;
        const { uri, name, lastModified } = asset;
        const outputFormat = uri.substring("data:".length, uri.indexOf(";base64"));

        const lightWeightURI = await H.resizeAndCompressBase64Img(uri);
        if (lightWeightURI) {
          compressedImages[`${name}_${lastModified}_${outputFormat}`] = lightWeightURI;
          count++;
        }
      }
      setSelectedImages(compressedImages);
    }
  }

  const handlePhotoRemove = (key) => {
    setSelectedImages((prevState) => {
      const { ...newState } = prevState;
      delete newState[key];
      return newState;
    });
  }

  const {
    data: overlappingSessions,
    loading: overlappingSessionsLoading,
    reload: overlappingSessionsReload,
  } = useResource({
    name: 'consulting-sessions',
    enabled: false,
    filter: {
      memberIDS: memberUIDS,
      onDate: dayjs(selectedDate).format('YYYY-MM-DD'),
    },
  });

  const { data: memberPersonalDataAutoComplete, loading: memberPersonalDataAutoCompleteLoading } = useResource({
    name: 'memberPersonalDataAutoComplete',
    cacheName: 'api-members-cache',
  });

  const { data: activities, loading: activitiesLoading } = useResource({
    name: 'activities',
    defaultData: [],
    cacheName: 'api-activities-cache',
  });

  const { data: cultures, loading: culturesLoading } = useResource({
    name: 'cultures',
    defaultData: [],
    cacheName: 'api-cultures-cache',
  });

  const getPersonalizedCulture = () => {
    const settings = UserSettingService.getSettings();
    const c = cultures.filter(culture =>
      settings.selectedCulture.includes(culture.uid)
    );
    return c[0];
  }

  useEffect(() => {
    const checkDataAndConnection = () => {
      if (isConnected) {
        const offlineData = localStorage.getItem('offlineData');
        if (offlineData) {
          setShowOfflineDialog(true);
        }
      }
    };

    checkDataAndConnection();
  }, [isConnected]);

  // This effect loads potentially overlapping sessions that was logged by another user
  useEffect(() => {
    if (!!selectedDate && memberUIDS.length > 0) {
      // selectedDate and memberUIDS are required 
      overlappingSessionsReload();
    }
  }, [selectedDate, memberUIDS]);

  const applyActivityFilterLogic = (activities) => {
    return activities.filter(a => {
        const isHidden = a?.hidden === '1';
        if(isHidden) {
            return false;
        }
        const hasUserSetting = activitiesSettings?.categories?.length > 0;
        if (!hasUserSetting) {
            return activitiesSettings.categories.includes(activity.categoryName);
        }
        return true;
    })
  }

  const handleChangeDate = (newValue) => {
    setSelectedDate(newValue);
    setstartDate(newValue ? newValue.$d : null);
  };

  const handleSaveHours = async () => {
    let updatedCountErrors = 0;

    if (!(memberUIDS && memberUIDS.length > 0)) {
      setShowErrorMember(true);
      updatedCountErrors += 1;
    } else {
      setShowErrorMember(false);
    }

    if (!hours && !minutes) {
      setShowErrorTime(true);
      updatedCountErrors += 1;
    } else {
      setShowErrorTime(false);
    }

    if (cultureUID.length === 0) {
      console.error(cultureUID);
      setShowError(true);
      updatedCountErrors += 1;
    } else {
      setShowError(false);
    }

    if (updatedCountErrors === 0) {
      const newConsultingSessionData = [];

      const promises = [];
      for (let i = 0; i < memberUIDS.length; i++) {
        const memberId = memberUIDS[i];
        const consultingSession = {
          user_uid: auth.uid,
          member_uid: memberId,
          activity_uid: activityUID,
          culture_uid: cultureUID,
          comment,
          startDate: selectedDate,
          hours,
          minutes,
          images: selectedImages,
        };
        newConsultingSessionData.push(consultingSession);
        promises.push(ConsultingSessionService.newConsultingSession(consultingSession));
      }

      setMemberName(null);
      setHistoryMemberID(null);

      if (isConnected) {
        await handleSaveHoursWhenOnline(promises);
      } else {
        // offline
        await handleSaveHoursWhenOffline(newConsultingSessionData);
      }
    }
  };

  const handleSaveHoursWhenOnline = async (promises) => {
    await Promise.all(promises);
    navigation.navigate(SCREEN.HOURS, {});
  };

  const handleSaveHoursWhenOffline = async (data) => {
    localStorage.removeItem('offlineData');
    localStorage.setItem('offlineData', JSON.stringify(data));
    navigation.navigate(SCREEN.HOURS, {});
  };

  const handleOfflineDialogYes = async () => {
    // Logic to handle reviewing data
    setShowOfflineDialog(false);
    let offlineData = localStorage.getItem('offlineData');
    offlineData = JSON.parse(offlineData);

    for (let i = 0; i < offlineData[0].member_uid.length; i++) {
      const memberId = offlineData[0].member_uid[i];
      const { activity_uid, culture_uid, place_uid, comment, startDate, hours, minutes } = offlineData[0];
      //const formattedStartDate = helper.formatDateToYYYYMMDD(new Date(startDate));
      const consultingSession = {
        user_uid: auth.uid,
        member_uid: memberId,
        activity_uid,
        place_uid,
        culture_uid,
        comment,
        startDate: selectedDate,
        hours,
        minutes,
      };
      await ConsultingSessionService.newConsultingSession(consultingSession);
      navigation.navigate(SCREEN.HOURS, {});
    }
    localStorage.removeItem('offlineData');
  };

  const handleOfflineDialogNo = () => {
    localStorage.setItem('offlineData', null);
    localStorage.removeItem('offlineData');
    setShowOfflineDialog(false);
  };

  return (
    <View style={{ marginHorizontal: 10 }}>
      <View>
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 2, paddingBottom: 2 }}>
          <Typography variant="h5">Beratungsstunden erfassen</Typography>
        </Box>
        <Box sx={{ marginY: 1, marginLeft: 2 }}>
          <Button
            style={styles.photoPickBtn}
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onClick={handlePhotoPick}
          >
            Foto
          </Button>
          {Boolean(Object.keys(selectedImages).length) && <View style={{ flex: 1 }}>
            <ImageList cols={5}>
              {Object.keys(selectedImages).map((key) => (
                <ImageListItem key={key} style={{ position: 'relative', width: '45', height: '45' }}>
                  <img
                    src={selectedImages[key]}
                    alt={key}
                    onClick={() => handlePhotoRemove(key)}
                    style={{ cursor: 'pointer' }}
                  />
                  <IconButton
                    onClick={() => handlePhotoRemove(key)}
                    style={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
                  >
                    ×
                  </IconButton>
                </ImageListItem>
              ))}
            </ImageList>
          </View>}
        </Box>
        <Box sx={{ mx: 2, mb: 2 }}>
          {!isConnected && <OfflineIndicator />}

          {!!overlappingSessions.length &&
            <OverlapSessionIndicator
              activities={activities}
              cultures={cultures}
              places={places}
              members={memberPersonalDataAutoComplete}
              consultingSessions={overlappingSessions}
            />
          }

          <FormControl fullWidth>
            <Autocomplete
              multiple
              autoHighlight
              blurOnSelect
              noOptionsText="Keine Ergebnisse gefunden"
              filterOptions={filterMemberOptions}
              options={memberPersonalDataAutoComplete}
              getOptionLabel={(value) => {
                if (memberPersonalDataAutoCompleteLoading) {
                  return value;
                }

                const memberData = memberPersonalDataAutoComplete.find((item) => {
                  let idToFind = !isNaN(value) ? parseInt(value, 10) : null;
                  if (!idToFind) {
                    idToFind = value?.memberID.toString();
                  }
                  return item?.memberID.toString() === idToFind.toString();
                });

                if (memberData) {
                  const { name, street, city, zip, memberID } = memberData;
                  return `${name}`;
                } else {
                  return 'Fehler';
                }
              }}
              renderOption={(props, option, index) => {
                const optionStyle = {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                };

                const nameStyle = {
                  fontSize: '16px',
                };

                const addressStyle = {
                  fontSize: '12px',
                };
                return (
                  <li {...props} key={option.memberID}>
                    <div style={optionStyle}>
                      <span style={nameStyle}>
                        {option?.name} <strong>[{option?.memberID}]</strong>
                      </span>
                      <span style={addressStyle}>
                        {option?.street}
                        {option?.zip ? `, ${option?.zip}` : ''}
                        {option?.city ? ` ${option?.city}` : ''}
                      </span>
                    </div>
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Mitglied" error={showErrorMember} helperText={showErrorMember ? 'Bitte wählen Sie mindestens ein Mitglied aus.' : ''} />}
              onChange={(event, options) => {
                setMemberUIDS(options.map((option) => option?.memberID));
              }}
            />
          </FormControl>
        </Box>
      </View>
      <View style={{ flex: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de" localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
          <MobileDatePicker
            disableFuture
            label="Datum"
            format="DD.MM.YYYY"
            value={selectedDate}
            onChange={handleChangeDate}
            closeOnSelect
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarMonthOutlinedIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ mx: 2, mb: 2 }}
          />
        </LocalizationProvider>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <Box sx={{ mx: 2, mb: 2 }}>
            <FormControl fullWidth error={showErrorTime}>
              <InputLabel id="time-select-label">Zeit erfassen</InputLabel>
              <Select
                labelId="time-select-label"
                id="time-select"
                value={selectedTime} // Selected value changed only on change
                label="Zeit erfassen"
                onChange={(event) => {
                  const selectedValue = event.target.value; // e.g., "2:30"
                  const selectedMinute = selectedValue.split(':')[1]; // Get selected minutes
                  const selectedHours = selectedValue.split(':')[0]; // Get selected hours
                  setSelectedTime(selectedValue); // Update selected value
                  setMinutes(selectedMinute);
                  setHours(selectedHours);
                }}
              >
                {timeList.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <Box sx={{ mx: 2, mb: 2 }}>
          <FormControl fullWidth>
            <Autocomplete
              autoHighlight
              blurOnSelect
              noOptionsText="Keine Ergebnisse gefunden"
              // if no activitiy is selected show all options OR only show options for selected activities in user profile
              options={applyActivityFilterLogic(activities)}
              getOptionLabel={(activity) => activity.name}
              filterOptions={(options, { inputValue }) => {
                return options.filter(option =>
                  option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option.categoryName.toLowerCase().includes(inputValue.toLowerCase())
                );
              }}
              renderInput={(params) => <TextField {...params} label="Tätigkeit" />}
              groupBy={(option) => (option?.categoryName || 'Anderes')}
              onChange={(event, value) => {
                if (value) {
                  setActivityUID(value.uid);
                } else {
                  setActivityUID('');
                }
              }}
            />
          </FormControl>
        </Box>
      </View>
      {cultures.length > 0 && <View style={{ flex: 1 }}>
        <Box sx={{ mx: 2, mb: 2 }}>
          <FormControl fullWidth>
            <Autocomplete
              autoHighlight
              blurOnSelect
              noOptionsText="Keine Ergebnisse gefunden"
              options={cultures}
              defaultValue={getPersonalizedCulture()}
              getOptionLabel={(cultures) => cultures.name}
              renderInput={(params) => <TextField {...params} label="Kultur" error={showError} helperText={showError ? 'Kultur darf nicht leer sein.' : ''} />}
              onChange={(event, value) => {
                if (value) {
                  console.log('Changing culture', value)
                  setCultureUID(value.uid);
                } else {
                  setCultureUID('');
                }
              }}
            />
          </FormControl>
        </Box>
      </View>}
      <TextField
        label="Bemerkung"
        placeholder="Bemerkung"
        value={comment}
        multiline
        rows={3}
        onChange={(event) => {
          setComment(event.target.value);
        }}
        sx={{ mx: 2, mb: 2 }}
      />
      <Button style={styles.savebtn} onClick={handleSaveHours} startIcon={<SaveIcon />}>
        Stunden speichern
      </Button>

      <Dialog open={showOfflineDialog && isConnected} onClose={handleOfflineDialogNo} aria-labelledby="offline-dialog-title" aria-describedby="offline-dialog-description">
        <DialogTitle id="offline-dialog-title">Ungespeicherte Daten</DialogTitle>
        <DialogContent>
          <DialogContentText id="offline-dialog-description">Sie haben ungespeicherte Beratungsstunden. Wollen sie mit dem OVR-Server synchronisieren?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowOfflineDialog(false)} color="primary">
            Nein, löschen
          </Button>
          <Button onClick={handleOfflineDialogYes} color="primary" autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </View>
  );
};

export default ConsultingSessionEnterForm;
